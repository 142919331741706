import { bindable, inject }     from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { BaseFormViewModel }    from 'base-form-view-model';
import { DialogController }     from 'aurelia-dialog';
import { FormSchema }           from 'modules/entities/owners/form-schema';
import { OwnersRepository }     from 'modules/entities/owners/services/repository';
import { OwnerFilesRepository } from 'modules/entities/owners/services/files-repository';

@inject(AppContainer, DialogController, FormSchema, OwnersRepository, OwnerFilesRepository)
export class EditOwnerModal extends BaseFormViewModel {

    @bindable formId = 'entities-owners-edition-form-modal';

    @bindable alert;
    @bindable settings = {
        title: 'form.title.edit-record',
        size:  'modal-lg',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param formSchema
     * @param repository
     * @param filesRepository
     */
    constructor(appContainer, dialogController, formSchema, repository, filesRepository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.formSchema       = formSchema;
        this.repository       = repository;
        this.filesRepository  = filesRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.owners.manage',
            'entities.owners.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema             = this.formSchema.schema(this);
            this.responsiblesSchema = this.formSchema.responsiblesSchema(this);
            this.settings.subtitle  = this.model.name;
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .update(this.model.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.dialogController.ok() : false);
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
