import { inject }                                  from 'aurelia-framework';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { Owner }                                   from 'modules/entities/models/owner';
import { OwnersRepository }                        from 'modules/entities/owners/services/repository';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(CountiesRepository, LocationsFormSchema, OwnersRepository, ParishesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param locationsFormSchema
     * @param ownersRepository
     * @param parishesRepository
     */
    constructor(countiesRepository, locationsFormSchema, ownersRepository, parishesRepository) {
        this.countiesRepository  = countiesRepository;
        this.locationsFormSchema = locationsFormSchema;
        this.ownersRepository    = ownersRepository;
        this.parishesRepository  = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Owner}
     */
    model() {
        let model = new Owner();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            id:    'owners_name',
            label: 'form.field.designation',
            size:  12,
        };

        this.address = {
            type:     'text',
            key:      'address',
            id:       'owners_address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            id:       'owners_zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            id:       'owners_locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            id:       'owners_phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            id:       'owners_mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.fax_number = {
            type:     'text',
            key:      'fax_number',
            id:       'owners_fax_number',
            label:    'form.field.fax-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'email',
            key:      'email',
            id:       'owners_email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            id:       'owners_tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            id:    'owners_status_id',
            label: 'form.field.status',
            size:  4,
        };

        let locationsSettings = {
            district: {
                id: 'owners_district_id',
            },
            county:   {
                id: 'owners_county_id',
            },
            parish:   {
                id: 'owners_parish_id',
            },
        };

        return [
            [this.name],
            ...this.locationsFormSchema.withSettings(locationsSettings).schema(viewModel.model, false),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status_id],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            required:     false,
            remoteSource: this.ownersRepository.getUsers.bind(this.ownersRepository, viewModel.model.id),
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToOwner(false),
            action:     () => viewModel.redirectToRoute('entities.owners.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
