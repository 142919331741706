import { bindable, inject }     from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { BaseFormViewModel }    from 'base-form-view-model';
import { FormSchema }           from 'modules/entities/owners/form-schema';
import { OwnersRepository }     from 'modules/entities/owners/services/repository';
import { OwnerFilesRepository } from 'modules/entities/owners/services/files-repository';

@inject(AppContainer, FormSchema, OwnersRepository, OwnerFilesRepository)
export class EditOwner extends BaseFormViewModel {

    @bindable headerTitle    = 'form.title.edit-record';
    @bindable newRecordRoute = 'entities.owners.create';
    @bindable formId         = 'entities-owners-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     * @param filesRepository
     */
    constructor(appContainer, formSchema, repository, filesRepository) {
        super(appContainer);

        this.formSchema      = formSchema;
        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.newRecordRoute  = appContainer.authenticatedUser.can(['entities.owners.manage', 'entities.owners.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/entities/owners/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        const authenticatedUser = this.appContainer.authenticatedUser;
        const belongsToEntity   = authenticatedUser.can('entities.owners.edit') && authenticatedUser.belongsToEntity(params.id);

        return super.authorize(authenticatedUser.can('entities.owners.manage') || belongsToEntity);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema             = this.formSchema.schema(this);
            this.responsiblesSchema = this.formSchema.responsiblesSchema(this);
            this.buttonsSchema      = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .update(this.model.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => this.afterResponseCallback(response));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Handles the received response
     *
     * @param response
     */
    afterResponseCallback(response) {
        if (response.status === true && ! this.appContainer.authenticatedUser.belongsToOwner(false)) {
            this.appContainer.router.navigateToRoute('entities.owners.index');
        }
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
