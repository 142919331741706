import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/entities/owners/form-schema';
import { OwnersRepository }  from 'modules/entities/owners/services/repository';

@inject(AppContainer, FormSchema, OwnersRepository)
export class CreateOwner extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.create-new-record';
    @bindable formId      = 'entities-owners-creation-form';

    alert  = {};
    model  = {};
    schema = {};

    creating = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/entities/owners/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.owners.manage',
            'entities.owners.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .create(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.appContainer.router.navigateToRoute('entities.owners.edit', { id: response.model.id }) : false);
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
